<template>
  <div
    v-if="showTopHeaderSign"
    class="wrapper-header d-flex align-center ">
    <a :href="linkToLanding">
      <img
        class="wrapper-header__logo"
        src="@/assets/icons/SOURCERY_poweredby_web.png">
    </a>
    <v-btn
      :to="{name:'sign-up'}"
      class="wrapper-header__btn"
      x-small
      color="lightBlue">
      try today
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'TopHeaderSignUp',
  computed: {
    linkToLanding() {
      return process.env.VUE_APP_LANDING_URL || 'https://gosourcery.com';
    },
    showTopHeaderSign() {
      return ['shared-collection', 'shared-workspace', 'project-library'].includes(this.$route.name);
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper-header {
  &__btn {
    height: 35px !important;
  }
  &__logo {
    height: 50px;
  }
}
</style>
